import { graphql } from 'gatsby';
import React from 'react';
import DashLine from '../components/DashLine';
import FacebookMessage from '../components/FacebookMessage/FacebookMessage';
import Layout from '../components/Layout/Layout';
import HelloBox from '../components/HelloBox';

export type Props = {
  data?: any;
};

const Oferta = ({
  data: { allContentfulImages, contentfulOfferPageContent },
}: Props) => {
  const logo = allContentfulImages.edges[0].node.logo.file.url;
  const backgroundDesktop =
    allContentfulImages.edges[0].node.backgrounds[0].file.url;

  return (
    <Layout
      title="Oferta"
      logo={logo}
      backgroundDesktop={backgroundDesktop}
      description="Zorganizuj swoją imprezę właśnie u nas"
      ogTitle="Oferta | Smaki Azji Siemianowice Śląskie"
      ogContent="Zorganizuj swoją imprezę właśnie u nas, odwiedź nas ma miejscu ! Smaki Azji Siemianowice Śląskie ul.Śląska 39"
    >
      <HelloBox heroImage={contentfulOfferPageContent} />
      <DashLine color="#3d6b0e" />
      <FacebookMessage />
    </Layout>
  );
};

export const getImages = graphql`
  query {
    contentfulOfferPageContent {
      boxImage {
        file {
          url
        }
      }
    }
    allContentfulImages {
      edges {
        node {
          logo {
            file {
              url
            }
          }
          backgrounds {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

export default Oferta;
