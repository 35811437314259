import React from 'react';
import styled from 'styled-components';

const StyledIFrame = styled.iframe`
  width: 500px;
  height: 500px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 2px 5px ${({ theme }) => theme.colors.gray};
`;

const DesktopFacebookMessage = () => (
  <>
    <StyledIFrame
      title="Desktop Fb widget"
      src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fsmakiazjisiemianowice&tabs=messages&width=500&height=500&small_header=true&adapt_container_width=false&hide_cover=false&show_facepile=true&appId=360701557894511"
      width="340"
      height="500"
      scrolling="no"
      allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
    />
  </>
);

export default DesktopFacebookMessage;
