import React from 'react';
import styled from 'styled-components';
import BoxPlaceholder from './BoxPlaceholder';
import StyledButton from './StyledButton';

const InfoPlaceholder = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  flex-flow: column nowrap;
`;

const H1 = styled.h1`
  margin: 10px 0;
  text-align: center;
  font-size: 2.3rem;
  color: ${({ theme }) => theme.colors.darkgreen};
  ${({ theme }) => theme.media.tablet} {
    font-size: 3rem;
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: 3.5rem;
  }
`;

const StyledParagraph = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.3rem;
  align-self: center;
  text-align: center;
  margin: 15px;
  ${({ theme }) => theme.media.tablet} {
    font-size: 1.8rem;
  }
  ${({ theme }) => theme.media.desktop} {
    font-size: 2.4rem;
  }
`;

const ImagePlaceholder = styled.div`
  padding: 15px 0;
  display: flex;
  justify-content: center;
`;
const Image = styled.img`
  border-radius: 40px;
  max-width: 220px;
  ${({ theme }) => theme.media.tablet} {
    max-width: 380px;
  } ;
`;

const HelloBox = ({ heroImage }: any) => {
  const { url } = heroImage.boxImage.file;
  return (
    <BoxPlaceholder>
      <InfoPlaceholder>
        <H1>Jesteśmy otwarci!</H1>
        <StyledParagraph>
          Mamy przyjemność zaprosić Państwa na spróbowanie naszych pysznych dań
          na wynos, lub z możliwością zjedzenia na miejscu.
        </StyledParagraph>
      </InfoPlaceholder>
      <ImagePlaceholder>
        <Image src={url} alt="rice" />
      </ImagePlaceholder>
      <StyledButton align="center" href="/kontakt">
        Zaprowadź
      </StyledButton>
    </BoxPlaceholder>
  );
};
export default HelloBox;
