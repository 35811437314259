import React from 'react';
import styled from 'styled-components';

const BoxWrapper = styled.section`
  background-color: ${({ theme }) => theme.colors.green};
  margin: 30px;
  padding: 10px 15px;
  width: 90%;
  display: flex;
  flex-flow: column nowrap;
  border-radius: 20px;
  align-items: center;
  box-shadow: 0 2px 5px ${({ theme }) => theme.colors.gray};
  ${({ theme }) => theme.media.tablet} {
    width: 75%;
  }
  ${({ theme }) => theme.media.desktop} {
    width: 70%;
  }
`;

type Props = {
  children?: any;
  className?: string;
};

const BoxPlaceholder = ({ children, className }: Props) => (
  <BoxWrapper
    className={className}
    data-sal="zoom-out"
    data-sal-duration="1000"
    data-sal-easing="ease"
  >
    {children}
  </BoxWrapper>
);

export default BoxPlaceholder;
