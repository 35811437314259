import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import MobileFacebookMessage from './MobileFacebookMessage';
import DesktopFacebookMessage from './DesktopFacebookMessage';

const Wrapper = styled.section`
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  margin: 15px 0;
`;

const H2 = styled.h2`
  margin: 15px;
  font-size: 2.2rem;
  color: ${({ theme }) => theme.colors.brown};
  text-align: center;
  ${({ theme }) => theme.media.bigDesktop} {
    font-size: 4rem;
  }
`;

const FacebookMessage = () => {
  const [, setWindow] = useState(false);
  const [width, setWidth] = useState(0);

  useEffect(() => {
    const getWindow = () => {
      if (window) {
        setWidth(window.innerWidth);
        setWindow(true);
      } else {
        setWindow(false);
      }
    };
    getWindow();
  }, []);
  const renderFacebookMessage = () => {
    if (width < 600) {
      return <MobileFacebookMessage />;
    }
    return <DesktopFacebookMessage />;
  };
  return (
    <Wrapper>
      <H2>Możesz wysłać do nas wiadomość!</H2>
      {renderFacebookMessage()}
    </Wrapper>
  );
};

export default FacebookMessage;
